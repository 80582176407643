/* spinner.css */
.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-left-color: rgb(90, 203, 234);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spinner-in-button {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 10px;
	height: 10px;
	align-items: center;
	border-radius: 50%;
	border-left-color: rgb(90, 203, 234);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* progress bar styles */
.progress-container {
	width: 100%;
	background-color: #f0f0f0;
	border-radius: 4px;
	margin: 10px 0;
	overflow: hidden;
}

.progress-bar {
	height: 10px;
	background-color: #009f6f;
	border-radius: 4px;
	transition: width 0.5s ease-in-out;
}

.generation-status {
	margin-top: 20px;
	text-align: center;
	max-width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.generation-percentage {
	font-size: 14px;
	margin-top: 5px;
	color: #444;
	font-weight: 600;
}
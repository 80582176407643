.coffee-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 320px;
    margin: 8px;
}

.card-content {
    display: flex;
    flex-direction: column;
}

.header-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 16px 16px;
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 5px solid #ddd;
    position: relative;
    min-height: 110px;
}

.header-left {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
    width: 75%;
    margin-right: 0;
    position: relative;
    z-index: 1;
}

.header-right {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2px;
    z-index: 1;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.header-right[style*="cursor: pointer"]:hover {
    background-color: rgba(245, 245, 245, 0.219);
}

.rating-web {
    font-size: 2.4rem;
    font-weight: 800;
    padding: 8px 0;
    padding-bottom: 30px;
    /* padding-left: 0; */
    line-height: 1;
    -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.1);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
}


.type-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.type {
    font-size: 1rem;
    color: #4b5563;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    margin-top: 0;
    text-align: left;
}

.shop-name {
    font-weight: 600;
    color: #333;
    font-size: 1.1rem;
    text-align: right;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    white-space: normal;
    line-height: 1.2;
    max-width: 100%;
    margin-left: auto;
    margin-bottom: 4px;
}

.header-right .coordinates-web {
    font-size: 0.65rem;
    padding: 2px 4px;
    margin-top: -2px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(197, 197, 197, 0.207);
    border-radius: 3px;
    transition: all 0.2s ease;
}

.header-right .coordinates-web:hover {
    background-color: rgba(111, 111, 111, 0.207);
}

.coffee-icon {
    width: 90px;
    object-fit: contain;
    opacity: 0.9;
    position: absolute;
    bottom: 0px;
    right: -5px;
    transform: scale(1.2);
    clip-path: inset(5% 5% 5% 5%);
    z-index: 0;
}

.image-section {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    padding: 0;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coffee-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}

.coffee-image.loaded {
    opacity: 1;
}

.image-placeholder {
    position: absolute;
    width: 40%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.image-placeholder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.7;
}

.fetch-error {
    position: absolute;
    bottom: -24px;
    font-size: 0.8rem;
    color: #ef4444;
    text-align: center;
    width: 100%;
    animation: none;
}

.image-placeholder.failed {
    animation: none;
}

@keyframes pulse {

    0%,
    100% {
        opacity: 0.7;
    }

    50% {
        opacity: 0.4;
    }
}

.map-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.button-container {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    gap: 4px;
    z-index: 11;
}

.toggle-button {
    border: none;
    background: rgba(255, 255, 255, 0.95);
    color: #555;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-size: 0.85rem;
    backdrop-filter: blur(4px);
    transition: all 0.2s ease-in-out;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.toggle-button:hover {
    background: white;
    color: #333;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.details-container {
    padding: 8px 12px;
}

.detailed-ratings {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    padding: 10px 16px 50px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    z-index: 10;
    transform: translateZ(0);
    will-change: opacity;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.detailed-ratings::-webkit-scrollbar {
    width: 6px;
}

.detailed-ratings::-webkit-scrollbar-track {
    background: transparent;
}

.detailed-ratings::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.detailed-ratings::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

.rating-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 0.8rem;
}

.rating-item:last-child {
    border-bottom: none;
}

.rating-item div:first-child {
    text-transform: capitalize;
    color: #4a5568;
}

.rating-item div:last-child {
    font-weight: 700;
    font-size: 0.9rem;
    transition: color 0.2s ease;
}

.location-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 0;
}

.location {
    color: #666;
    font-size: 0.85rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
}

.location::after {
    content: '';
    flex: 1;
    height: 1px;
    background: linear-gradient(to right, #ddd, transparent);
}

.details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    color: #888;
    width: 100%;
}

.details-left {
    display: flex;
    align-items: center;
}

.details-row .tweet {
    margin-top: 0;
    padding: 3px 6px;
    font-size: 0.7rem;
}

.coordinates-web-container {
    display: flex;
    align-items: center;
    gap: 6px;
}

.coordinates-web {
    font-family: monospace;
    background: #f5f5f5;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease;
}

.coordinates-web:hover {
    background: #e5e5e5;
    color: #333;
}

.coordinates-web .external-link-icon {
    font-size: 0.7rem;
    opacity: 0.7;
}

.open-maps-button {
    background: none;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    color: #666;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease;
    border-radius: 4px;
    background: #f5f5f5;
}

.open-maps-button:hover {
    color: #333;
    background-color: #e5e5e5;
}

.date {
    font-style: italic;
    color: #888;
}

.tweet {
    margin-top: 3px;
    padding: 4px 8px;
    background-color: transparent;
    border: 1px solid #333;
    border-radius: 4px;
    font-size: 0.7rem;
    color: #333;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
    width: fit-content;
}

.tweet:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.twitter-icon {
    font-size: 1rem;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateY(0) translateZ(0);
        opacity: 1;
    }
}

.slide-up {
    animation: slideUp 0.25s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

/* Admin control styles */
.admin-controls {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 20;
}

.admin-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #4a90e2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-icon:hover {
    background-color: #3a7bc8;
    transform: scale(1.05);
}

.admin-icon.delete-icon {
    background-color: #ff4d4d;
}

.admin-icon.delete-icon:hover {
    background-color: #ff0000;
}

.delete-button {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: #ff4444;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.delete-button:hover {
    background-color: #ff0000;
    transform: scale(0.98);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.coffee-card-placeholder {
    width: 100%;
    height: 400px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 8px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.format-display {
    display: flex;
    gap: 8px;
    margin-top: 4px;
    position: relative;
    padding: 4px 6px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    z-index: 2;
}

.format-icon {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 0.8rem;
    cursor: help;
    transition: all 0.2s ease;
    opacity: 0.8;
    -webkit-text-stroke: 0.5px rgba(0, 0, 0, 0.2);
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0.2),
        -1px 0 0 rgba(0, 0, 0, 0.2),
        0 1px 0 rgba(0, 0, 0, 0.2),
        0 -1px 0 rgba(0, 0, 0, 0.2);
}

.format-icon:hover {
    opacity: 1;
}

.format-value {
    font-size: 0.7rem;
    color: #666;
    text-transform: lowercase;
    -webkit-text-stroke: initial;
    text-shadow: none;
}

.format-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    transform: none;
    background: rgba(33, 33, 33, 0.95);
    color: white;
    padding: 12px 16px;
    border-radius: 6px;
    font-size: 0.75rem;
    min-width: 140px;
    white-space: normal;
    word-break: normal;
    text-align: left;
    z-index: 1000;
    margin-top: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);
    letter-spacing: 0.02em;
    line-height: 1.4;
}

.tooltip-row {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 4px 0;
}

.tooltip-label {
    color: #9ca3af;
    font-weight: 600;
    text-transform: none;
    font-size: 0.7rem;
}

.tooltip-value {
    color: white;
    font-size: 0.8rem;
    padding-left: 4px;
}

.format-tooltip::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 12px;
    width: 8px;
    height: 8px;
    background: rgba(33, 33, 33, 0.95);
    transform: rotate(45deg);
}

/* icon styles */
.format-icon .sugar-icon,
.format-icon .milk-icon {
    font-size: 1rem;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.3);
    text-shadow: none;
}

.format-icon .sugar-icon {
    margin-right: -2px;
}

.format-icon .sugar-icon:last-of-type {
    margin-right: 2px;
}

.format-icon .sugar-icon path,
.format-icon .milk-icon path {
    stroke: rgba(0, 0, 0, 0.3);
    stroke-width: 15;
}

.edit-icon {
    margin-left: 2px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.edit-icon:hover {
    color: rgba(0, 0, 0, 0.9);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

.edit-container {
    padding: 16px;
    background-color: #f9f9f9;
}

.edit-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.edit-section h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}

.edit-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.edit-group label {
    font-size: 0.85rem;
    font-weight: 600;
    color: #555;
}

.edit-subgroup {
    display: flex;
    gap: 8px;
}

.edit-subgroup input {
    flex: 1;
}

.edit-rating-item,
.edit-format-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    padding: 4px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.edit-rating-item label,
.edit-format-item label {
    font-size: 0.8rem;
    font-weight: normal;
    text-transform: capitalize;
}

.edit-rating-item input,
.edit-format-item input {
    width: 80px;
    text-align: right;
}

.edit-section input,
.edit-section select,
.edit-section textarea {
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    background-color: white;
    transition: border-color 0.2s ease;
}

.edit-section input[type="datetime-local"] {
    font-family: inherit;
    width: 100%;
    cursor: pointer;
}

.edit-section input:focus,
.edit-section select:focus,
.edit-section textarea:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.edit-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 16px;
}

.edit-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: all 0.2s ease;
}

.edit-button.save {
    background-color: #4caf50;
    color: white;
}

.edit-button.save:hover {
    background-color: #43a047;
}

.edit-button.save:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
}

.edit-button.cancel {
    background-color: #f5f5f5;
    color: #666;
}

.edit-button.cancel:hover {
    background-color: #e0e0e0;
    color: #333;
}

.delete-button-container {
    z-index: 2;
}

.edit-format-category {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.edit-format-category:last-child {
    border-bottom: none;
}

.edit-format-category>label {
    display: block;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #444;
}

.edit-format-item {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}

.edit-format-item label {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 3px;
}

.edit-format-item select {
    padding: 6px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 0.85rem;
    color: #333;
}

.edit-format-item select:focus {
    outline: none;
    border-color: #aaa;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.format-options {
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
}

.rating-categories {
    max-height: 300px;
    overflow-y: auto;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 10px;
    margin-top: 5px;
}

.edit-rating-item.required label {
    font-weight: 500;
}

.edit-rating-item.optional label {
    font-style: italic;
    color: #666;
}

.required-indicator {
    color: #e54343;
    margin-left: 4px;
    font-weight: bold;
}

.weight-indicator {
    margin-left: 6px;
    font-size: 0.7rem;
    color: #888;
    font-weight: normal;
    font-style: italic;
}

/* Coffee Cards Grid */
.coffee-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}
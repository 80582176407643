/* Add Coffee Form Styles */
.add-coffee-card {
    width: 80%;
    max-height: 80vh;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
}

.add-coffee-card-header {
    padding: 15px 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-coffee-card-header h2 {
    margin: 0;
    font-size: 1.3rem;
    color: #333;
}

.add-coffee-card-header .close-button {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #777;
    cursor: pointer;
    transition: color 0.2s;
}

.add-coffee-card-header .close-button:hover {
    color: #333;
}

.add-coffee-card-content {
    padding: 20px;
    overflow-y: auto;
    max-height: calc(80vh - 60px);
    /* account for header height */
}

.add-coffee-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-section {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 15px;
}

.form-section h3 {
    margin: 0 0 15px 0;
    font-size: 1.1rem;
    color: #444;
}

.form-group {
    margin-bottom: 12px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #555;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #2bb941;
    box-shadow: 0 0 0 2px rgba(43, 185, 65, 0.1);
}

.form-row {
    display: flex;
    gap: 15px;
}

.form-row .form-group {
    flex: 1;
}

.required-label::after {
    content: "*";
    color: #e54343;
    margin-left: 4px;
}

.image-upload-section {
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    transition: all 0.2s;
    margin-bottom: 15px;
}

.image-upload-section.dragging {
    background-color: rgba(43, 185, 65, 0.1);
    border-color: #2bb941;
}

.image-upload-section p {
    margin: 10px 0;
    color: #666;
}

.image-upload-section input {
    display: none;
}

.image-upload-button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 15px;
    font-size: 0.9rem;
    color: #555;
    cursor: pointer;
    transition: all 0.2s;
}

.image-upload-button:hover {
    background-color: #eee;
    color: #333;
}

.image-preview-container {
    margin-top: 15px;
}

.image-preview {
    max-width: 100%;
    max-height: 200px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.submit-button {
    background-color: #2bb941;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 10px;
}

.submit-button:hover {
    background-color: #25a037;
}

.submit-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.step-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
}

.step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #777;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.step-circle.active {
    background-color: #2bb941;
    color: white;
}

.step-circle.completed {
    background-color: #25a037;
    color: white;
}

.step-label {
    font-size: 0.8rem;
    color: #777;
}

.step-label.active {
    color: #333;
    font-weight: 500;
}

/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

/* For success/error messages */
.status-message {
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.status-message.success {
    background-color: rgba(43, 185, 65, 0.1);
    border: 1px solid #2bb941;
    color: #25a037;
}

.status-message.error {
    background-color: rgba(229, 67, 67, 0.1);
    border: 1px solid #e54343;
    color: #e54343;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease-in-out infinite;
    margin-right: 10px;
}

/* Location GPS container styles */
.location-gps-container {
    margin-bottom: 16px;
}

.get-location-button {
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 16px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, transform 0.1s;
}

.get-location-button:hover {
    background-color: #3367d6;
    transform: translateY(-1px);
}

.get-location-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
}

.location-error {
    color: #e53935;
    margin-top: 8px;
    font-size: 14px;
}

.location-icon {
    margin-right: 8px;
    font-size: 16px;
}
.coffee-page {
    max-width: 1400px;
    margin: 0 auto;
    padding: 24px;
}

.coffee-page-header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.page-header-left {
    display: flex;
    align-items: center;
}

.page-title {
    font-size: 1.8rem;
    margin: 0;
    padding-right: 10px;
}

.admin-toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 12px;
}

.admin-toggle-button:hover {
    background-color: #f5f5f5;
    color: #333;
}

.delete-button {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ff4444;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.delete-button:hover {
    background-color: #ff0000;
    transform: translateY(-50%) scale(0.98);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.sort-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.sort-select {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    background-color: white;
    font-size: 0.9rem;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s ease;
    padding-right: 32px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.sort-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #6b7280;
    pointer-events: none;
}

.coffee-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    align-items: start;
}

.coffee-type-section {
    margin-bottom: 32px;
}

.coffee-type-header {
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 2px solid #e5e7eb;
}

.coffee-type-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #374151;
    display: flex;
    align-items: center;
    gap: 8px;
}

.coffee-type-count {
    font-size: 0.9rem;
    color: #6b7280;
    font-weight: normal;
}

.coffee-type-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    align-items: start;
}

.load-more-trigger {
    height: 40px;
    margin: 20px auto;
    text-align: center;
    color: #9ca3af;
    font-size: 0.9rem;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-more-trigger::before,
.load-more-trigger::after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 1px;
    background-color: #d1d5db;
    margin: 0 10px;
}

.add-coffee-header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: #555;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 12px;
}

.add-coffee-header-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transform: translateY(-1px);
}

.add-coffee-header-button svg {
    font-size: 16px;
}

@media (max-width: 768px) {

    .coffee-grid,
    .coffee-type-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 12px;
    }

    .coffee-page {
        padding: 16px;
    }

    .coffee-page-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .sort-controls {
        width: 100%;
    }

    .sort-select {
        width: 100%;
    }
}